.sub-sidenav {
	&__title {
		font-weight: 700;
	}

	&__name {
		margin: 0 auto 0 0 !important;
	}

	&__item {
		display: flex;
		align-items: center;
		padding: 10px 0;
		border-bottom: 1px solid #ebebeb;
	}

	&__remove {
		padding: 0.3rem !important;
		border-radius: 50% !important;
		margin-right: 0 !important;
		margin-left: 15px !important;

		.icon {
			height: 1rem !important;
			width: 1rem !important;
			line-height: 1rem !important;
			font-size: 0.8rem !important;
		}
	}

	&__panel {
		display: block;
		border-bottom: 1px solid rgba(34, 36, 38, 0.15);

		&-item {
			display: flex;
			align-items: center;
			padding: 10px 0;

			&--nested {
				margin-left: 35px;
			}
		}

		&-title {
			margin: 0 auto 0 0;
		}
	}
}
