@use "@angular/material" as mat;

@import "components/all";

@import url("https://fonts.googleapis.com/css?family=Lato");

/* For ng select component */
@import "@ng-select/ng-select/themes/default.theme.css";

/* Used in Time Component */
@import "ng-zorro-antd/ng-zorro-antd.min.css";

@import "variables";

$mat-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Lato",
	"Noto Sans", Oxygen, Ubuntu, "Droid Sans", "Helvetica Neue", sans-serif;
$mat-font-size: 14px;

html,
body {
	margin: 0;
	height: 100%;
	background-color: $elevated_black;
	font-family: $mat-font-family;
	font-size: $mat-font-size;
}

.dark-theme {
	background-color: $elevated_black;
}

@mixin color($theme) {
	md-input {
		color: mat.get-theme-color($theme, primary, 500);
	}
}

.app-container {
	height: 100%;
	background-color: inherit;
}

.blend {
	opacity: 0.56;
}

.top-buffer {
	margin-top: $unit;
}
.bottom-unbuffer {
	margin-bottom: (-1 * $unit);
}

@mixin color($theme) {
	a {
		color: mat.get-theme-color($theme, primary, 500);
	}
}

@mixin color($theme) {
	.error {
		color: mat.get-theme-color($theme, warn, 500);
	}
}

.register-wrapper {
	display: table;
	width: 100%;
	height: 100%;

	.register-container {
		display: table-cell;
		vertical-align: middle;
	}
}

dashboard-layout {
	md-sidenav {
		md-divider {
			margin-bottom: 32px;
			background-color: $header-divider-color;
			opacity: 0.54;
		}
	}
}

dashboard-header {
	padding-top: 1px;
	padding-bottom: $header-padding;
	color: white;

	.logo-header {
		display: block;
		height: 50px;
		width: 120px;
		float: left;
		margin-top: 6px;
		background-image: url("/assets/logo_text_black.png");
		background-size: contain;
		background-repeat: no-repeat;
	}

	.profile-wrapper {
		// .show-profile {

		// }

		.profile-image {
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			padding-right: 10px;
		}
	}
}

dashboard-layout {
	background-color: #fff;

	.content-container {
		display: block;
		height: 100%;
		width: 100%;
		.dashboard-content {
			margin-left: $side-menu-width;
			overflow-x: hidden;
		}
	}
}

// .pagination {
//     display: inline-block;
// }

// .pagination a {
//     color: black;
//     float: left;
//     padding: 8px 16px;
//     text-decoration: none;
//     transition: background-color .3s;
//     border: 1px solid #ddd;
// }

// .pagination a.active {
//     background-color: #4CAF50;
//     color: white;
//     border: 1px solid #4CAF50;
// }

// .pagination a:hover:not(.active) {background-color: #ddd;}

file-drop {
	text-align: center;
}

.ui.multiple.dropdown {
	.text.default {
		overflow: inherit !important;
	}
}

.mat-menu-content {
	min-width: 13 * $unit;
	background: #fff;
	border-radius: 3px;
	box-shadow:
		0 4px 8px -2px rgba(9, 30, 66, 0.25),
		0 0 1px rgba(9, 30, 66, 0.31);
	box-sizing: border-box;
	overflow: auto;
	padding: 4px 0;
}

.mat-tab-label {
	font-weight: 500 !important;
}

.mat-button-toggle-group {
	box-shadow: none !important;
}

.mat-button-toggle {
	border-radius: 5px !important;
	margin-right: $unit !important;
}

@mixin color($theme) {
	.mat-button-toggle.mat-button-toggle-checked {
		color: white !important;
		background-color: mat.get-theme-color($theme, primary, 500);
		border-style: none;
	}
}

.mat-button-toggle-label {
	margin-top: 4px !important;
	font-weight: 500 !important;
}

.mat-tab-label:focus {
	background-color: transparent !important;
}

button.back-button {
	background-color: #fafafa;
}

button.action-bar-button {
	span.action-bar-button-label {
		display: inline-block !important;
		vertical-align: middle !important;
		line-height: 24px !important;
	}
}

fieldset {
	border-style: none;
	padding: 0 !important;
	margin: 0px !important;

	legend {
		line-height: 1.28571429em;
		font-weight: 700;
		padding: 0;
		margin: 0px;
		font-size: 1.6em;
		padding-top: 1em;
		padding-bottom: 1em;
	}
}

.dont-break-out {
	/* These are technically the same, but use both */
	overflow-wrap: break-word;
	word-wrap: break-word;

	-ms-word-break: break-all;
	/* This is the dangerous one in WebKit, as it breaks things wherever */
	word-break: break-all;
	/* Instead use this non-standard one: */
	word-break: break-word;

	/* Adds a hyphen where the word breaks, if supported (No Blink) */
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

.measureEventEditAction {
	width: 12%;
	text-align: center !important;
}

.measureEventWeightValue {
	width: 12%;
}

.measureEventUser {
	width: 16%;
}

.measureEventDate {
	width: 20%;
}

.showRelatedEquipmentRecordsControl {
	margin-top: 1em;
}

#profileIcon {
	background-color: map-get($xiroji-blue, 500);
	margin: auto;
	border-radius: 50%;
	color: black;
	font-weight: bold;
	cursor: pointer;
	user-select: none;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
}

#helpPopup {
	margin: auto;
}

.popupFlexContainer {
	display: flex;
}

.profilePopupInitials {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: map-get($xiroji-blue, 500);
	border-radius: 50%;
	color: black;
	font-weight: bold;
	width: 2.5em;
	height: 2.5em;
	font-size: 1.8em;
	align-self: center;
	flex: none;
}

.profilePopupTextContainer {
	padding-left: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.userLogoutText {
	cursor: pointer;
}

.profilePopupNameText {
	font-size: 1.4em;
	font-weight: bolder;
}

.profilePopupActions {
	display: flex;
	justify-content: space-between;
}

#profileUpdateButtons {
	text-align: right;
}

/* Allows CdkTextareaAutosize to properly calculate size to resize to  */
textarea.cdk-textarea-autosize-measuring {
	height: auto !important;
	overflow: hidden !important;
	padding: 14px 11px !important;
	box-sizing: content-box !important;
}

/* Additional styles to support resizing text area automatically in readOnly mode */
textarea.autosizeHelper {
	overflow: hidden !important;
	min-height: 40px;
}

.ui.form textarea[readonly="true"],
.ui.input textarea[readonly="true"],
textarea[readonly="true"] {
	resize: none;
}

.ng-select.custom {
	display: grid;
}

.ng-select.custom.manualDisableOpacity {
	opacity: 0.45;
	pointer-events: none;
}

.ng-select.custom.manualDisableOpacity > div {
	background-color: white !important;
}

.ng-select.custom div.ng-value {
	font-size: 1em !important;
	border: 0 solid transparent;
	padding: 4px 2px;
}

.ng-select-multiple.custom div.ng-value {
	background-color: #e8e8e8 !important;
	color: rgba(0, 0, 0, 0.6);
	font-weight: 700;
	box-shadow: inset 0 0 0 1px rgb(34 36 38 / 15%);
	border-radius: 0.25em !important;
	padding: 2px 2px;
}

#labelbuilder {
	display: flex;
	justify-content: space-between;
}

#custom-label-options {
	min-width: 40%;
	min-height: 600px;
	max-width: 40%;
	padding-left: 8px;
	padding-right: 8px;
}

#custom-label-preview {
	min-width: 60%;
	min-height: 600px;
	max-width: 60%;
	padding-left: 8px;
	padding-right: 8px;
}

#custom-label-image-outer-container {
	min-height: 250px;
	min-width: 100%;
}

#custom-label-image-inner-container {
	display: flex;
	justify-content: center;
}

#custom-label-image {
	border: 1px solid black;
}

#previewTemplateButton {
	float: right;
}

// Tree view related
.example-tree-invisible {
	display: none;
}

.example-tree ul,
.example-tree li {
	margin-top: 0;
	margin-bottom: 0;
	list-style-type: none;
}

/*
   * This padding sets alignment of the nested nodes.
   */
.example-tree .mat-nested-tree-node div[role="group"] {
	padding-left: 40px;
}

/*
   * Padding for leaf nodes.
   * Leaf nodes need to have padding so as to align with other non-leaf nodes
   * under the same parent.
   */
.example-tree div[role="group"] > .mat-tree-node {
	padding-left: 40px;
}

div.supSearchContainer {
	display: flex;
	flex-wrap: wrap;
}

div.supSearchCardOuter {
	border: 2px solid black;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-left: 6px;
	padding-right: 6px;
	background-color: whitesmoke;
	margin: 2px;
	cursor: pointer;
}

div.supSearchCardOuter:hover {
	background-color: #2185d0;
	color: whitesmoke;
}

div.supSearchCardInnerPrimary {
	display: flex;
	padding-top: 6px;
	padding-bottom: 3px;
	min-width: 250px;
	max-width: 100%;
	justify-content: space-between;
	font-weight: bold;
}

div.supSearchCardInnerSecondary {
	display: flex;
	font-size: 12px;
	text-transform: uppercase;
	width: 100%;
	justify-content: space-between;
}

div.supSearchCardInnerEntity {
	display: flex;
}

div.supSearchCardInnerDate {
	display: flex;
}

div.supSearchCardInnerName {
	display: flex;
	font-size: 24px;
}

div.supSearchCardInnerId {
	display: flex;
	background-color: black;
	border: 1px solid black;
	border-radius: 6px;
	padding-left: 3px;
	padding-right: 3px;
	color: whitesmoke;
	font-size: 12px;
}

div.supSearchCardDivider {
	border: 1px solid black;
	border-radius: 6px;
	height: 1px;
	display: flex;
	width: 100%;
}

.superSearchInput {
	display: inline-flex;
	border-radius: 6px;
	font-size: 24px;
}

.superSearchLoadingIndicator {
	display: inline-flex;
	margin-left: 8px;
	font-size: 10px;
	font-family: monospace;
}

.superSearchMatchText {
	background-color: #fdf0e4;
	margin-left: 2px;
	padding-left: 4px;
	padding-right: 4px;
	border-radius: 2px;
}

.formSearchInputContainer {
	width: 600px;
	@media only screen and (max-device-width: 768px) {
		width: 100%;
		max-width: 320px;
	}
}

.formSearchCardContainer {
	width: 100%;
}

.formSearchListScroll {
	height: 400px;
	overflow-y: scroll;
}

.labelBuilderExpandIcon {
	cursor: pointer;
}

fui-popup {
	font-weight: normal;
	font-size: smaller;
	white-space: pre-wrap;
}

label {
	p {
		font-weight: normal;
		font-size: smaller;
	}

	span {
		font-weight: normal;
		font-size: smaller;
		white-space: pre-wrap;
	}
}

.pdf-button {
	background: #e0e1e2 none;
	background-color: rgba(0, 0, 0, 0.05);
	color: rgba(0, 0, 0, 0.6);

	.dropdown.icon.button {
		background-color: rgba(0, 0, 0, 0.118);
		&:hover {
			background-color: #cacbcd;
			background-image: none;
			box-shadow:
				0 0 0 1px transparent inset,
				0 0 0 0 rgba(34, 36, 38, 0.15) inset;
			color: rgba(0, 0, 0, 0.8);
		}
		&:active {
			background-color: #babbbc;
			color: rgba(0, 0, 0, 0.9);
		}
		&:last-child {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
}

.ui.red.button {
	background-color: rgba(127, 29, 29, 0.9);
}

/*** Super Search Styles ***/
ng-select.supersearch-select {
	width: 320px;
	@media only screen and (max-device-width: 768px) {
		width: 100%;
		max-width: 320px;
	}
}
ng-dropdown-panel.supersearch-select {
	max-width: 85% !important;
	width: auto !important;

	.superSearchContent {
		div {
			span {
				text-wrap: wrap;
			}
		}
	}
}
