@use "@angular/material" as mat;

@import "variables";

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

@include mat.core();

@include mat.all-component-themes($light-theme);

.dark-theme {
	@include mat.core-color($dark-theme);
}
