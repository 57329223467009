@use "@angular/material" as mat;

$black-87-opacity: rgba(black, 0.87);
$white-87-opacity: rgba(white, 0.87);

$xiroji-light-blue: #23b0ff;
$xiroji-blue: #1e84eb;
$xiroji-dark-blue: #0667c9;
$xiroji-background: #f4f5f7;

$xiroji-blue: (
	50: #e3f2fd,
	100: $xiroji-light-blue,
	200: #90caf9,
	300: #64b5f6,
	400: #42a5f5,
	500: $xiroji-blue,
	600: #1e88e5,
	700: $xiroji-dark-blue,
	800: #1565c0,
	900: #0d47a1,
	A100: #82b1ff,
	A200: #448aff,
	A400: #2979ff,
	A700: #2962ff,
	contrast: (
		50: $black-87-opacity,
		100: $black-87-opacity,
		200: $black-87-opacity,
		300: $black-87-opacity,
		400: $black-87-opacity,
		500: white,
		600: white,
		700: white,
		800: $white-87-opacity,
		900: $white-87-opacity,
		A100: $black-87-opacity,
		A200: white,
		A400: white,
		A700: white,
	),
);

$primary: mat.define-palette($xiroji-blue, 500);
$accent: mat.define-palette(mat.$light-blue-palette, 600, A100, A400);
$warn: mat.define-palette(mat.$red-palette, 900);

$theme-typography: mat.define-typography-config();

$dark-theme: mat.define-dark-theme(
	(
		color: (
			primary: $primary,
			accent: $accent,
			warn: $warn,
		),
		typography: $theme-typography,
	)
);
$light-theme: mat.define-light-theme(
	(
		color: (
			primary: $primary,
			accent: $accent,
			warn: $warn,
		),
		typography: $theme-typography,
	)
);

$elevated_blue: #4083e4;
$elevated_black: #010509;
