.sensor-graph {
	&__wrap {
		margin-top: 20px;
		position: relative;
	}

	&__graph {
		overflow: hidden;

		&:not(.empty)::after {
			content: "Click a Segment to Edit";
			display: block;
			position: absolute;
			top: 30px;
			right: 30px;
			padding: 10px 20px;
			border-radius: 4px;
			background: black;
			color: white;
			font-weight: bold;
			opacity: 0;
			transition: opacity 0.3s ease;
			will-change: opacity;
		}

		&:hover::after {
			opacity: 1;
		}
	}

	&__column {
		width: auto !important;
	}

	&__separator {
		margin: 0 10px;
	}

	&__hidden {
		overflow: hidden;
		height: 10px;
		opacity: 0;
		position: absolute;
		left: 0;
		right: 0;
	}
}
