.sidenav-confirm {
	text-align: center;

	&__icon {
		margin: 30px 0 20px !important;
	}

	&__title {
		font-weight: bold;
	}

	&__button {
		display: inline-block;
		margin: 5px;
	}
}
