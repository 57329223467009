.ui.checkbox.toggle.secondary {
	label {
		&::before,
		&::after {
			border: 0.3rem solid #999999 !important;
			background: transparent !important;
			transition: transform 0.3s ease;
			box-shadow: none !important;
		}

		&::before {
			width: 2.5rem;
		}

		&::after {
			width: 1.5rem;
		}
	}

	input {
		left: auto;
		right: 0;
	}

	&.true input,
	input:checked {
		& + label::before {
			border-color: #21ba45 !important;
			background: #21ba45 !important;
		}

		& + label::after {
			border-color: #21ba45 !important;
			background: white !important;
			transform: translateX(1rem);
		}
	}

	&.partial input {
		& + label::before {
			border-color: #fbbd08 !important;
			background: #fbbd08 !important;
		}

		& + label::after {
			border-color: #fbbd08 !important;
			background: white !important;
			transform: translateX(1rem);
		}
	}

	&.right {
		label {
			padding-left: 0;
			padding-right: 3.5rem;

			&::before,
			&::after {
				left: auto !important;
				right: 0 !important;
			}

			&::after {
				right: 1rem !important;
			}
		}
	}

	&.left {
		label {
			padding-left: 3.5rem;
			padding-right: 0;

			&::before,
			&::after {
				left: 0 !important;
				right: auto !important;
			}
		}
	}
}
