.sui-dropdown {
	display: block !important;
	position: relative !important;
	width: 100% !important;
	height: 0 !important;
	overflow: visible !important;
	z-index: 1100;

	&__wrap {
		position: absolute !important;
		top: 77px !important;
		right: 0 !important;
		left: auto !important;
		max-width: 420px !important;
		min-width: auto !important;
		border-radius: 0 !important;
		max-height: calc(100vh - 77px) !important;
		overflow-x: hidden !important;
		overflow-y: auto !important;

		* {
			white-space: normal;
		}
	}
}
