.th-sidenav {
	&__toggle {
		margin-bottom: 1rem;
	}

	&__segment {
		@keyframes reveal {
			from {
				background-color: #21ba45;
			}
			to {
				background-color: #ebebeb;
			}
		}

		$self: &;

		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		position: relative;
		padding: 15px;
		background: #ebebeb;
		border-radius: 5px;

		&:not(:last-child) {
			margin-bottom: 10px;
		}

		&::after {
			content: ".";
			display: block;
			height: 0;
			clear: both;
			visibility: hidden;
		}

		&--reveal {
			animation-name: reveal;
			animation-duration: 0.3s;
			animation-fill-mode: forwards;
		}

		&-input {
			width: 55px;

			& input {
				padding: 10px 0 !important;
				line-height: 20px !important;
				text-align: center !important;
			}
		}

		&-separator {
			color: #999999;
			margin: 0 auto;
		}

		&-wrap {
			padding-right: 30px;
			margin-right: -10px;
		}

		&-toggle {
			margin-left: 10px;

			& label {
				padding-left: 2.5rem !important;
			}
		}

		&-button {
			background: transparent !important;

			&:last-of-type {
				padding-right: 0 !important;
			}

			&--add {
				position: absolute;
				bottom: -15px;
				right: -30px;
			}

			&--delete {
				position: absolute;
				right: -30px;
				bottom: 50%;
				transform: translateY(50%);
			}

			&--round {
				padding: 0.3rem !important;
				border-radius: 50% !important;

				.icon {
					height: 1rem !important;
					width: 1rem !important;
					line-height: 1rem !important;
					font-size: 0.8rem !important;
				}
			}
		}
	}

	&__form {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;

		&-wrap:not(:last-child) {
			margin-bottom: 2rem;
		}

		&-title {
			font-weight: bold;
		}

		&-description {
			color: #999999;
		}

		&-input {
			width: 80px;
		}

		&-separator {
			color: #999999;
			margin: 0 1rem;
		}
	}
}
