.notif-button {
	font-size: 25px !important;
	padding: 0 !important;
	background: transparent !important;
	vertical-align: middle !important;
	margin-right: 20px !important;

	&__badge {
		transform: scale(0.7) !important;
		top: -5px !important;
	}

	.icon {
		color: black;

		&:hover {
			color: black;
		}
	}
}

.notif-dropdown {
	&__header {
		&:not(:last-child) {
			border-bottom: 1px solid rgba(34, 36, 38, 0.15);
		}
	}

	&__cta {
		color: #0485f3 !important;
		background: transparent !important;
		padding: 0 !important;
	}
}

.notif-dropdown__empty {
	margin: 20px 0;
}

.notif-item {
	&__wrap {
		cursor: pointer;
		position: relative !important;
		padding: 10px 10px 10px 40px !important;

		&:not(:last-child) {
			border-bottom: 1px solid rgba(34, 36, 38, 0.15);
		}
	}

	&__icon {
		position: absolute;
		left: 12px;
		top: 17px;
	}

	&__title {
		margin: 5px 0 10px !important;
		font-size: 16px !important;
	}

	&__type {
		text-transform: capitalize;

		&::after {
			content: ":\00a0"; // NBSP
		}
	}

	&__list {
		margin: 0 !important;
	}

	&__subtitle {
		display: inline-block;
		margin-right: 15px;
		margin-bottom: 5px;
		opacity: 0.3;
		text-transform: uppercase;
		font-size: 12px !important;
	}

	&__tag {
		margin-bottom: 5px !important;
	}
}
