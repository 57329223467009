alert-subscribe {
	position: relative;
	display: inline-block;
	vertical-align: top;
	z-index: 1;

	&:not(:last-child) {
		margin-right: 10px;
	}
}

.alert-subscribe {
	&__placeholder {
		position: absolute;
		overflow: hidden;
		clip: rect(0 0 0 0);
		height: 1px;
		width: 1px;
		margin: -1px;
	}

	&__wrap {
		position: absolute;
		right: 0;
		top: 0;
		background: #f4f4f4;
		text-align: left;
		white-space: nowrap;
		border-radius: 3px;
		overflow: hidden;
		min-width: 300px;
	}

	&__title {
		padding: 10px 20px;
		background: #e9e9e9;
		font-weight: bold;
	}

	&__content {
		padding: 15px 20px;
	}

	&__cta {
		padding: 15px 0 0 !important;
		color: #0485f3 !important;
		background: transparent !important;

		&:hover {
			background: transparent !important;
		}
	}
}
